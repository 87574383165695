import React from "react"
import ProjectCard from "./project-card"

export const ProjectListHome = ({ data }) => {
  const posts = data.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <ProjectCard key={edge.node.id} data={edge.node} />)
    .slice(0, 3)
  return (
    <section className="container--work container--work--project">
      <div className="project-list">
        <h2>Projects</h2>
        <div className="grids md-2 lg-3">{posts}</div>
      </div>
    </section>
  )
}

export default ProjectListHome
