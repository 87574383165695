import React from "react"
import { Link } from "gatsby"

const PostMaker = ({ data }) => (
  <section className="container--work container--work--small">
    <div className="home-posts">
      <h2>Posts</h2>
      <div className="posts">{data}</div>
    </div>
  </section>
)

const PostList = ({ data }) => (
  <div className={data.frontmatter.ready ? `post-list` : `post-list not-ready`}>
    <Link to={data.frontmatter.slug} aria-label={data.frontmatter.title}></Link>
    <div>
      <p className="title">
        <span className="emoji">{data.frontmatter.emoji}</span>
        {data.frontmatter.title}
      </p>
    </div>
    <div className="meta">
      <p className="date">
        <time>{data.frontmatter.date}</time>
      </p>
      <p className="time">
        {data.timeToRead > 1
          ? `${data.timeToRead}  mins read.`
          : `${data.timeToRead}  min read.`}
      </p>
    </div>
  </div>
)

export const BlogListHome = ({ data }) => {
  const posts = data.edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostList key={edge.node.id} data={edge.node} />)
  return <PostMaker data={posts} />
}

export default BlogListHome
