import React from "react"
import { motion } from "framer-motion"

const blackBox = {
  initial: {
    height: "100%",
  },
  animate: {
    height: 0,
    top: "100vh",
    transition: {
      duration: 1.5,
      ease: [0.87, 0, 0.13, 1],
    },
  },
}

const InitialTransition = ({ id }) => {
  return (
    <motion.div
      key={id}
      className="fixed z-50 w-full bg-black"
      initial="initial"
      animate="animate"
      variants={blackBox}
      onAnimationStart={() => {
        document.body.classList.add("overflow-hidden")
      }}
      onAnimationComplete={() => {
        document.body.classList.remove("overflow-hidden")
      }}
    />
  )
}

export default InitialTransition
