import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Work from "../components/work"
import ProjectListHome from "../components/project-list-home"
import BlogListHome from "../components/blog-list-home"
import Testimonial from "../components/testimonial"
import { AnimatePresence } from "framer-motion"
import InitialTransition from "../components/transition"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    home: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        tagline
        job
        featuredImage {
          childImageSharp {
            fluid(
              maxWidth: 960
              maxHeight: 600
              quality: 90
              srcSetBreakpoints: [960, 1440]
            ) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }

    projects: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "project" } } }
      limit: 3
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 540, maxHeight: 360, quality: 80) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }

    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "blog-post" } } }
      limit: 5
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
          timeToRead
        }
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { home, projects, posts } = data
  const { frontmatter } = home

  console.log(data)
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.fluid
    : ""

  return (
    <AnimatePresence exitBeforeEnter>
      <InitialTransition id="home" />
      <Layout className="full" bleed={true}>
        <SEO />
        <section className="container--home">
          <div className="home-banner">
            <div className="about">
              <h1 className="name">{frontmatter.title}</h1>
              <h1 className="job">{frontmatter.job}</h1>
              <p className="tagline">{frontmatter.tagline}</p>
            </div>
            <div className="images">
              {Image ? (
                <Img
                  fluid={Image}
                  alt={frontmatter.title + " - Featured image center"}
                  className="featured-image main"
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </section>
        <Work />
        <ProjectListHome data={projects} />
        <Testimonial />
        {posts.length >= 0 && <BlogListHome data={posts} />}
      </Layout>
    </AnimatePresence>
  )
}

export default HomePage
