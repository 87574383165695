import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { motion } from "framer-motion"

const transition = { duration: 0.5, ease: "easeInOut" }

const postPreviewVariants = {
  initial: { x: "100px", opacity: 0 },
  enter: { x: 0, opacity: 1, transition },
  exit: { x: "-100px", opacity: 0, transition },
}

const ProjectCard = ({ data }) => (
  <motion.article className="project-card" variants={postPreviewVariants}>
    <Link to={data.frontmatter.slug}>
      <Img
        fluid={data.frontmatter.featuredImage.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"
        alt={data.frontmatter.title + " - Featured image"}
        className="featured-image"
      />
      <div className="content">
        <h2 className="title">{data.frontmatter.title}</h2>
        <p className="desc">{data.frontmatter.description}</p>
      </div>
    </Link>
    <div className="hover"></div>
  </motion.article>
)

export default ProjectCard
