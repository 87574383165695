import React, { useState, useEffect } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri"
import charles from "../../static/assets/charles.png"
import biwon from "../../static/assets/biwon.png"
import mudia from "../../static/assets/mudia.png"

const variants = {
  enter: dir => {
    return {
      x: dir > 0 ? 200 : -200,
      opacity: 0,
    }
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: dir => {
    return {
      zIndex: 0,
      x: dir < 0 ? 200 : -200,
      opacity: 0,
    }
  },
}

const testimonials = [
  {
    index: 0,
    name: "Biwon Grace",
    picture: biwon,
    role: "Application Analyst, Relativity Poland",
    content:
      "If there’s a guy to give complex end-end design, it’s definitely Kennedy. He understands the perfect blend between UI and UX.",
    email: "abubiwom@gmail.com",
  },
  {
    index: 1,
    name: "Mudia Imasuen",
    picture: mudia,
    role: "UX Designer, Printivo",
    content:
      "Kennedy puts his best foot forward. Sometimes I'd like to think he has an unfair advantage of being a genius. Working with him is always a pleasure.",
    email: "sirmudiadavid@gmail.com",
  },
  {
    index: 2,
    name: "Charles Njoku",
    picture: charles,
    role: "UI/UX Designer, ALX",
    content:
      "Kennedy displays outstanding quality in his design process and has a knack for usable and impactful designs.",
    email: "charlesunn@gmail.com",
  },
]

const Testimonial = () => {
  const [[index, dir], setIndex] = useState([0, 0])

  const paginate = itemIndex => {
    setIndex(prevState => [itemIndex, itemIndex > prevState[0] ? 1 : -1])
  }

  useEffect(() => {
    const tabs = document.querySelectorAll('[role="tab"]')
    let nextTab = 0
    const arrowKeyHandler = event => {
      switch (event.key) {
        case "ArrowRight":
          nextTab++
          if (nextTab >= tabs.length) {
            nextTab = 0
          }

          tabs[nextTab].focus()
          break
        case "ArrowLeft":
          nextTab--
          if (nextTab < 0) {
            nextTab = tabs.length - 1
          }

          tabs[nextTab].focus()
          break

        default: {
        }
      }
    }
    document.addEventListener("keydown", arrowKeyHandler)
    return () => {
      document.removeEventListener("keydown", arrowKeyHandler)
    }
  }, [index])

  return (
    <section className="container--work testimonials__container">
      <div className="testimonials">
        <header>
          <h3>Testimonials</h3>
        </header>
        <div className="main">
          <div className="people" role="tablist">
            {testimonials.map(testimonial => {
              const active = testimonial.index === index
              return (
                <div
                  role="tab"
                  aria-selected={active}
                  key={testimonial.index}
                  id={`tab-${testimonial.index}`}
                  aria-controls={`panel-${testimonial.index}`}
                  tabIndex={active ? "0" : "-1"}
                  onClick={() => paginate(testimonial.index)}
                  onKeyUp={event => {
                    if (event.keyCode === 13) {
                      event.preventDefault()
                      paginate(testimonial.index)
                    }
                  }}
                  className={`person ${active ? `person--active` : ""}`}
                >
                  <img
                    src={testimonial.picture}
                    alt={testimonial.name}
                    loading="lazy"
                  />
                  <div>
                    <p className="name">{testimonial.name}</p>
                    <p className="role">{testimonial.role}</p>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="indicators">
            {testimonials.map(t => (
              <div
                key={t.index}
                aria-hidden={true}
                onClick={() => paginate(t.index)}
                className={`${
                  t.index === index ? `underline--active` : `underline`
                }`}
              ></div>
            ))}
          </div>
          <div
            role="tabpanel"
            className="content"
            id={`panel-${index}`}
            aria-labelledby={`tab-${index}`}
          >
            <div className="text">
              <AnimatePresence initial={false} custom={dir}>
                <motion.q
                  key={index}
                  custom={dir}
                  exit="exit"
                  initial="enter"
                  animate="center"
                  variants={variants}
                  transition={{
                    duration: 2,
                    ease: "anticipate",
                    type: "spring",
                    stiffness: 500,
                    damping: 30,
                  }}
                >
                  {testimonials[index].content}
                </motion.q>
              </AnimatePresence>
            </div>
            <a href={`mailto:${testimonials[index].email}`}>
              <span role="img" aria-label="mailbox">
                📬
              </span>{" "}
              Make enquiry
            </a>
          </div>
        </div>
      </div>
      <div className="mobile-indicators">
        <RiArrowLeftSLine
          size="40"
          onClick={() => {
            paginate(index - 1 < 0 ? testimonials.length - 1 : index - 1)
          }}
        />

        {testimonials.map(t => (
          <div
            key={t.index}
            aria-hidden={true}
            onClick={() => paginate(t.index)}
            className={`${t.index === index ? `track--active` : `track`}`}
          ></div>
        ))}

        <RiArrowRightSLine
          size="40"
          onClick={() => paginate((index + 1) % testimonials.length)}
        />
      </div>
    </section>
  )
}

export default Testimonial
