import React from "react"
import Framer from "../../static/assets/framer.png"
import Figma from "../../static/assets/figma.png"
import Adobe from "../../static/assets/adobe.png"
import Illustrator from "../../static/assets/illustrator.png"
import Sketch from "../../static/assets/sketch.png"
import Photoshop from "../../static/assets/photoshop.png"

export const Work = () => {
  return (
    <section className="container--work container--work--main ">
      <div className="work grids md-1 lg-2">
        <div>
          <h2>Skills</h2>
          <div className="skills">
            <hr />
            <div>
              <p>UI Design</p>
              <p>UX Design</p>
              <p>UX Research</p>
            </div>
          </div>
        </div>
        <div>
          <h2>Tools</h2>
          <div className="tools">
            <hr />
            <div>
              <img src={Figma} loading="lazy" alt="Figma" />
              <img src={Illustrator} loading="lazy" alt="Illustrator" />
              <img src={Photoshop} loading="lazy" alt="Photoshop" />
              <img src={Framer} loading="lazy" alt="framer" />
              <img src={Adobe} loading="lazy" alt="Adobe" />
              <img src={Sketch} loading="lazy" alt="Sketch" />
            </div>
          </div>
        </div>
      </div>
      <div className="image"></div>
    </section>
  )
}

export default Work
